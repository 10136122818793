<template>
  <div class="game-content">
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(banner, index) in bannerList" :key="index">
        <img class="img" :src="banner.imageUrl" @click="onStartGame(banner.gameId)"/>
      </van-swipe-item>
    </van-swipe>

    <div class="tab-content">
      <van-tabs v-model="active" color="#6387ea" title-active-color="#6387ea" >
          <van-tab>
              <template #title> 
                  <van-icon class-prefix="my-icon" name="game"/> 新遊上架
              </template>
              <ul class="game-list" v-if="gameList.length">
                <li v-for="(game, index) in gameList" :key="index">
                    <img :src="game.imageUrl" class="game-img" />
                    <div class="txt">
                        <p class="txt-title">
                          <span>{{ game.title }}</span>
                          <van-tag v-for="(tag, tagindex) in game.tags" :key="tagindex" class="tag" :color="tag.color">{{ tag.label }}</van-tag>
                        </p>
                        <p class="txt-gray">{{ game.content }}</p>
                    </div>
                    <div class="button">
                        <a type="button" class="button-start" @click="onStartGame(game.id)">開始</a>
                        <a v-if="game.downloadUrl.length" type="button" class="button-start" @click="onDownLoad(game.downloadUrl)">下載</a>
                    </div>
                </li>
              </ul>
              <van-empty description="暫無數據" v-else/>
          </van-tab>

          <van-tab>
              <template #title> 
                  <van-icon name="notes-o"/> 开服咨询
              </template>
              <van-empty description="暫無數據"/>
          </van-tab>

      </van-tabs>

      <nav-bar />
     
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from "vue";
import { useRouter } from "vue-router";

import NavBar from '@/components/NavBar'
import { Toast } from 'vant'
import { requestBanner, requestGameList } from "@/service/web";
import { validatenull } from '@/utils/validate';
import { useStore } from 'vuex'

export default {
  name: "game",
  setup() {
    const router = useRouter();
    const store = useStore()

    const state = reactive({
       bannerList: [],
       gameList:[],
    });

    onMounted(() => {
        init();
    });

    const init = async () => {
        // Toast.loading('加載中');
        store.commit("setNeedLogin", false);
        let result = await requestBanner();
        if (result) {
            state.bannerList = result.bannerList;
            result = await requestGameList();
            state.gameList = result.gameList;
            for (const game of state.gameList) {
                game.tags = [];
                if (!validatenull(game.label)) {
                    let list = game.label.split('|');
                    for (let i = 0; i < list.length; i++) {
                        let label = list[i].split(',');
                        game.tags.push({
                          color:label[0],
                          label:label[1],
                        })
                    }
                }
            }
        }
        Toast.clear()
    };

    const onStartGame = (gameId) => {
        goTo("/gameplay/" + gameId)
    }

    const onDownLoad = (url) => {
        window.open(url);
    }

    const goTo = (url, params) => {
        router.push({ path: url, query: params})
    }

    return {
      ...toRefs(state),
      onStartGame,
      onDownLoad
    };
  },
  components: {
      NavBar,
  },
};
</script>

<style lang="less" scoped>
.game-content {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    overflow-y: scroll;
}

.img {
  width: 100%;
}

.game-list {
      background: #fff;
      cursor: pointer;
      position: relative;
      margin-top: 10px;
      padding: 10px;
      height: calc(var(--vh, 1vh) * 100 - 50px);

      li {
          overflow: hidden;
          padding: 5px;
          border-bottom: 1px solid #f1f1f1;
          display: flex;
          display: -webkit-flex;
          align-items:center;
          justify-content:center;

          &:last-child {
              border-bottom: 0px;
          }

          .game-img {
              width: 60px;
              height: 60px;
              margin-right: 10px;
              border-radius: 10px;
          }

          .txt {
            text-align: left;
            width: 300px;
            line-height: 20px;
            .txt-title {
                font-size: 16px;
                color: #333;
                margin: 0 0 5px;
                .tag {
                    margin-left: 5px;
                }
            }
            .txt-gray {
                color: #999;
                font-size: 13px;
            }
          }

          .button {
              width: 50px;
              line-height: 25px;
          }

          .button-start {
              display: inline-block;
              width: 50px;
              background: #6e87ea;
              color: #fff;
              font-size: 16px;
              text-align: center;
              border-radius: 5px;
              line-height: 30px;
              margin-top: 5px;
          }

      }
  }

  @media screen and (min-width: 640px) {
    .game-list {
      li {
          .txt {
              width: 500px;
          }
          .button-start,
          .button {
              width: 70px;
          }
      }
    }
}
</style>