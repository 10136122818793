import axios from '../utils/axios'

export function requestBanner() {
    return axios.get('/web/banner')
}

export function requestGameList() {
    return axios.get('/web/gameList')
}

export function requestInfo(id) {
    return axios.get(`/web/game/${id}`)
}